* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::selection {
  background-color: $primary-color;
  color: $white;
}

::-moz-selection {
  background-color: $primary-color;
  color: $white;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
  font-family: $font-outfit, $font-serif;
  background-color: var(--body-color);
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--font-color), 1);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(var(--font-color), 1);
    border-radius: 10px;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

p {
  margin: 0;
}

ul {
  padding: 0;

  li {
    display: inline-block;
  }
}

button {
  &:active {
    border-color: $transparent !important;
  }
}
.badge {
  padding: 5px 12px;
  font-size: 13px;
}
.f-title-light {
  color: var(--gray-100);
}

.fw-500 {
  font-weight: 500;
}

// Buttons
$btn-name: primary, secondary, danger, warning, success, info, light;

$button-color: $primary-color, $secondary-color, $danger-color, $warning-color, $success-color, $info-color, $light-color1;

@each $var in $btn-name {
  $i: index($btn-name, $var);

  .btn-#{$var} {
    border-color: nth($button-color, $i) !important;
    background-color: nth($button-color, $i) !important;

    &:hover,
    &:active {
      border-color: nth($button-color, $i) !important;
      background-color: nth($button-color, $i) !important;
    }
  }

  .btn-outline-#{$var} {
    border-color: nth($button-color, $i) !important;
    color: nth($button-color, $i) !important;

    &:hover,
    &:active {
      border-color: nth($button-color, $i) !important;
      color: $white !important;
      background-color: nth($button-color, $i) !important;
    }
  }

  .bg-#{$var} {
    background-color: nth($button-color, $i) !important;

    &:hover,
    &:active {
      background-color: nth($button-color, $i) !important;
    }
  }
  .text-#{$var} {
    color: nth($button-color, $i) !important;
  }
  .badge-#{$var} {
    background-color: nth($button-color, $i) !important;
  }
}

.common-flex {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-f-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.custom-multisig {
  &.badge-light-success {
    background-color: rgba($secondary-color, 0.1) !important;
    &.card {
      .card-body {
        padding: 20px;
      }
    }
  }
  .w-full {
    flex-wrap: wrap;
    gap: 15px;
    .d-flex {
      flex-wrap: wrap;
      gap: 7px;
      .btn {
        height: 32px;
      }
    }
  }
  .multisig-row {
    .multisig-title {
      font-size: small;
      font-weight: 700;
      color: $text-color;
    }
    .shortAddress {
      // text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      // max-width: 150px;
      color: rgba($black, 0.4);

      font-size: 12px;
      cursor: pointer;
    }
    .d-flex {
      i {
        color: var(--font-color) !important;
      }
    }
  }
  .multisig-mem {
    padding: 0px 0px 20px;
    .team-count {
      color: $stroke-icon;
    }
    .team-title {
      display: block;
    }
    display: none;
    opacity: 0;
    visibility: hidden;
    &.is-open {
      visibility: visible;
      display: block;
      opacity: 1;
    }
  }
  .dropdown {
    .dropdown-toggle {
      // padding: 0px ;
      background-color: transparent !important;
      border: 1px solid $stroke-icon !important;
      color: $stroke-icon !important;
      line-height: 1.2;
    }
  }

  .custom-send-btn {
    background-color: transparent !important;
    border: 1px solid $stroke-icon !important;
    color: $primary-color;
    i {
      color: $primary-color !important;
    }
    &:hover {
      background-color: $primary-color !important;
      color: $white;
      i {
        color: $white !important;
      }
    }
    &.custom-primary-btn {
      border: none !important;
      i {
        color: $white !important;
      }
    }
  }
  .card-body {
    padding-bottom: 0px;
  }
  .multi-count {
    color: $stroke-icon;
    border: none;
    text-align: end;
    padding: 8px 0px 20px;
    background-color: unset !important;
  }
}
.common-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.badge-light-danger {
  background-color: rgba($danger-color, 0.1);
  color: $danger-color;
  font-weight: 500;
}
.badge-light-warning {
  background-color: rgba($warning-color, 0.1);
  color: $warning-color;
  font-weight: 500;
}
.badge-light-success {
  background-color: rgba($font-color, 0.1);
  color: var(--font-color);
  font-weight: 500;
}
.transaction-badge {
  position: absolute;
  right: -6px;
  top: -10px;
  padding: 4px 7px;
  border-radius: 50%;
  font-size: 10px;
}
// custom scrollbar
.custom-scrollbar,
.modal-content {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba($font-color, 0.2);
  }
  &::-webkit-scrollbar {
    width: 3px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($font-color, 0.3);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Common autofill inputs

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active:not(.auth-content .form-group .form-control) {
  -webkit-box-shadow: 0 0 0 30px $light-color1 inset !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// mixins
@mixin data-no-found($height, $padding-block, $fz, $color) {
  height: $height;
  padding-block: $padding-block;
  font-size: $fz;
  color: $color;
  @content;
}

@mixin financial-flex($display, $justify, $align) {
  display: $display;
  justify-content: $justify;
  align-items: $align;
}

// Notify Animation
@keyframes notify {
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(8deg);
  }
  80% {
    transform: rotate(-8deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// roles table //
.roles-table {
  thead {
    tr {
      th {
        &:last-child {
          text-align: center;
        }
      }
    }
  }
  .common-flex {
    .outer-box {
      .btn {
        padding: 0;
      }
      .trash-icon {
        svg {
          stroke: #fc564a;
        }
      }
    }
  }
}
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;

  .error-heading {
    margin-top: 115px;

    .headline {
      font-size: 285px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-top: -130px;
    }
  }

  .sub-content {
    font-size: 18px;
    color: var(--font-color);
    letter-spacing: 1px;
    font-weight: normal;
    line-height: 35px;
    z-index: 3;
    position: relative;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .btn {
    margin-top: 40px;
  }
}

.role-input-group {
  .form-control {
    color: #1b1b1b;
    &:focus {
      color: #1b1b1b;
    }
    &:hover {
      color: #1b1b1b;
    }
  }
}
.permissions {
  .d-flex {
    .form-check {
      width: 16%;
    }
  }
}
.role-form-button {
  .btn {
    &:last-child {
      background-color: rgba($font-color, 1) !important;
      border-color: rgba($font-color, 1) !important;
    }
  }
}
.custom-dropdown {
  display: flex !important;
  justify-content: end;
  align-items: center;
  background-color: unset !important;
  border-radius: 8px;
  .btn-secondary {
    background-color: var(--font-color) !important;
    border-color: var(--font-color) !important;
    color: var(--white) !important;
    width: 110px !important;
    font-size: 18px !important;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  .dropdown-menu {
    background-color: $white;
    z-index: 1;
    transform: translate(0px, 42px) !important;
    &.show {
      .dropdown-item {
        &:active,
        &.active {
          background-color: rgba($font-color, 1);
        }
      }
    }
  }
}
.apexcharts-canvas {
  z-index: 1;
}

.helper-text {
  color: $text-color;
  font-size: small;
  font-style: italic;
  margin-block: 8px 0;
}

.custom-textarea {
  height: 200px;
  margin-bottom: 2px;
  overflow: auto;
  .signatory-item {
    &:hover {
      border: 1px solid $font-color;
      border-radius: 7px;
      padding: 1px;
      padding-left: 5px;
    }
    // padding-left: 10px;
    color: #777;
    padding-left: 5px;

    cursor: pointer;
  }
  .signatory-img {
    height: 20px;
    width: 20px;
  }
}
.centered-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px; /* Adjust based on your design */
  text-align: center; /* Optional for multi-line alignment */
  color: #6c757d; /* Optional for a muted text color */
}
.custom-bath-calls {
  position: relative;
  .close-round-icon {
    cursor: pointer;
    color: $danger-color;
    position: absolute;
    right: 0;
    top: -8px;
    text-align: end;
  }
}
.custom-chain-heading {
  color: var(--table-font-color);
  font-weight: 500;
  background-color: transparent;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 150px;
    color: $stroke-icon;
    font-size: 12px;
    cursor: pointer;
  }
}
.modal {
  &:has(.custom-send-modal) {
    overflow: hidden;
  }
}
.modal-dialog {
  &.custom-send-modal {
    .modal-content {
      height: 350px;
      overflow: auto;
    }
  }
}
.total-balance-card {
  // border: 1px solid rgba($placeholder-color, 0.2);
  color: var(--font-color);
  font-weight: 500;
  display: flex;
  gap: 20px;
  // padding: 15px;
  margin-bottom: 25px;
  > div {
    background-color: rgba(135, 143, 151, 0.1) !important;
    padding: 12px;
    border-radius: 7px;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
    max-width: 150px;
    font-weight: 600;
    color: rgba($placeholder-color, 0.9);
    font-size: 14px;
    cursor: pointer;
  }
}

.signing{
  position: fixed;
  z-index: 1001;
  overflow: hidden;
  top: 87px;
  right: 30px;
    // position: absolute;
    // height: 100vh;
    // width: 100%;
    // inset: 0;
    // overflow: hidden;
    // backdrop-filter: blur(3px);
  > .wrapper {
    text-align: end;
    > .gif-box{
      background: rgba($info-color,0.6);
      border-radius: 0.25rem;
      padding: 0.5rem;
      margin-bottom: 5px;
      .variant-push{
        img{
          border-radius: 50%;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%, -50%);
          // position: absolute;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) and (min-width: 1200px) {
  .custom-multisig {
    .w-full {
      .d-flex {
        gap: 5px;
        .btn {
          height: 30px;
          padding: 5px 8px !important;
          font-size: 13px;
          i {
            font-size: 14px;
            vertical-align: baseline;
          }
        }
      }
    }
  }
}
.transaction-modal {
  .modal-body {
    .form-group {
      position: relative;
      input[type="file"] {
        ~ i {
          position: absolute;
          top: 39px;
          right: 8px;
          color: rgba($danger-color, 1);
          font-style: normal !important;
        }
      }
    }
  }
}

.budget-modal{
  &.multising-budget-model{
    .modal-body{
      .form-group{
        .d-flex{
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .form-control{
            max-width: 90%;
          }
        }
      }
    }
  }
  .extrinsic-link{
    color: $info-color;
  }
}