/**=====================
  5.7 Sidebar CSS Start
==========================**/

.page-wrapper {
  position: relative;
  .page-header {
    max-width: 100vw;
    background-color: var(--white);
    @media screen and (max-width: 991px) { 
      margin-left: 0!important; 
      width: calc(100% - 0px) !important; 
    }
   
    .header-wrapper {
      width: 100%;
      align-items: center;
      // padding: $header-wrapper-padding;
      position: relative;

      @media (max-width: 1550px) and (min-width: 1200px) {
        padding: 16px 20px;
      }
      
      .serchinput {
        display: none !important;

        .serchbox {
          position: relative;
        }

        .search-form {
          top: 50px;
          position: absolute;
          transition: all linear 0.3s;
          right: -50px;
          transform: translateY(-35px) scaleY(0);
          opacity: 0;
          visibility: hidden;
          width: 180px;
          margin-left: 12px;

          @media (max-width: 480px) {
            right: -120px;
          }

          &.open {
            transform: translateY(0px) scaleY(1);
            opacity: 1;
            visibility: visible;
            transition: all linear 0.3s;
          }

          input {
            padding: 10px 15px;
            // background-color: $light;
          }
        }
      }

      .nav-right {
        justify-content: flex-end;

        ul {
          li {
            svg {
              // vertical-align: $header-wrapper-nav-icon-align;
              // width: $header-wrapper-nav-icon-size;
              // height: $header-wrapper-nav-icon-size;
              stroke: none;
              // fill: var(--theme-default);
              transition: all 0.5s ease;

              path {
                // color: $header-wrapper-nav-icon-color;
              }
            }

            .mode {
              &.active {
                svg {
                  rotate: 180deg;
                }
              }
            }
          }
        }

        &.right-header {
          ul {
            li {
              .mode {
                cursor: pointer;
                text-align: center;

                i {
                  font-size: 18px;
                  font-weight: 600;
                  transition: all 0.3s ease;

                  &.fa-lightbulb-o {
                    font-weight: 500;
                    font-size: 22px;
                    transition: all 0.3s ease;
                    animation: zoomIn 300ms ease-in-out;
                  }
                }
              }

              .header-search {
                cursor: pointer;
              }

              .profile-media {
                img {
                  width: 40px;
                  margin: 0px;
                }

                .flex-grow-1 {
                  margin-left: 10px;

                  span {
                    font-weight: 500;
                  }

                  p {
                    font-size: 12px;
                    line-height: 1;
                    // color: $theme-body-sub-title-color;
                  }
                }
              }
            }
          }
        }

        >ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          >li {
            padding: 9px 10px;
            display: inline-block;
            position: relative;
            // background-color: var(--light-color);
            border-radius: 50%;
            margin-right: 15px;
            transition: all 0.5s ease;
            cursor: pointer;

            [dir="rtl"] & {
              margin-right: unset;
              margin-left: 15px;
            }

            &:hover {
              background-color: rgba($primary-color, 0.1);
              transition: all 0.5s ease;

              svg {
                // fill: var(--theme-default);
                transition: all 0.5s ease;
              }
            }

            @media (max-width: 420px) {
              padding: 6px 8px;
              margin-right: 5px;
            }

            &:last-child {
              background-color: unset !important;
              margin-right: 0;

              [dir="rtl"] & {
                margin-right: unset;
                margin-left: 0;
              }
            }

            &:nth-child(7) {
              @media (max-width: 630px) {
                display: none;
              }
            }

            .badge {
              position: absolute;
              right: -15px;
              top: -15px;
              height: 16px;
              width: 16px;
              display: flex;
              align-items: center;
              font-size: 10px;
              font-weight: 600;
              justify-content: center;
            }

            span {
              ul {
                left: inherit;
                right: -10px;
                width: 130px;

                &:before,
                &:after {
                  left: inherit;
                  right: 10px;
                }

                li {
                  display: block;

                  a {
                    font-size: 14px;
                    color: var(--dark-color);

                    i {
                      margin-left: 10px;
                      font-size: 13px;
                      color: var(--dark-color);
                    }
                  }
                }
              }
            }

            .message {
              position: relative;
            }
          }

          .flag-icon {
            font-size: 16px;
          }
        }

        .bookmark-flip {
          width: unset !important;
          box-shadow: unset !important;
          background-color: transparent !important;
          overflow: initial;

          &::after,
          &::before {
            display: none;
          }

          .Typeahead-menu {
            top: 28%;
            max-height: 154px;
          }

          .bookmark-dropdown {
            li {
              &.list-group-item {
                &:first-child {
                  height: 120px;
                  overflow: auto;
                }
              }
            }

            .bookmark-content {
              text-align: center;

              .bookmark-icon {
                width: 52px;
                height: 52px;
                background-color: #f9f9f9;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
              }

              span {
                // font-family: $font-inter, $font-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0.5px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: normal;
                // color: $theme-font-color; 
              }
            }
          }
        }

        .flip-card {
          width: 300px;
          height: 260px;
          background-color: transparent;

          .flip-card-inner {
            position: relative;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;

            .front,
            .back {
              position: absolute;
              backface-visibility: hidden;
              border-radius: 10px;
              overflow: hidden;
              box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
              width: 300px;

              button {
                border: none;
              }
            }

            .front {
              background-color: var(--white);
              transition: .3s;

              svg {
                stroke: var(--dark-color);
                fill: none;
                transition: all 0.3s ease;

                &:hover {
                  // stroke: var(--theme-default);
                  transition: all 0.3s ease;
                }
              }
            }

            .back {
              background-color: var(--white);
              transform: rotateY(180deg);

              li {
                &:first-child {
                  // margin-top: 20px;
                  height: 235px !important;
                }
              }

              button {
                width: 100%;
              }

              .flip-back-content {
                input {
                  border-radius: 25px;
                  padding: 6px 12px;
                  width: 100%;
                  border: 1px solid #ddd;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }

            &.flipped {
              transform: rotateY(180deg);

              .front {
                opacity: 0;
              }
            }
          }
        }

        .onhover-show-div {
          width: 330px;
          top: 50px;
          right: 0;
          left: unset;

          .dropdown-title {
            padding: 20px;
            text-align: center;
            // border-bottom: 1px solid rgba($dropdown-border-color, 0.3);
          }

          ul {
            padding: 15px;

            li {
              .d-flex {
                position: relative;

                .message-img {
                  padding: 5px;
                  border-radius: 50%;

                  img {
                    position: relative;
                    border-radius: 50%;
                    width: 40px !important;
                    height: auto;
                    margin: 0;
                  }
                }

                .flex-grow-1 {
                  margin-left: 15px;

                  h5 {
                    a {

                      // color: $theme-font-color;
                      [class="dark-only"] & {
                        color: rgba($white, 0.6);
                      }
                    }
                  }

                  p {
                    margin-bottom: 0;
                    opacity: 0.6;
                    line-height: 1.4;
                  }
                }

                .notification-right {
                  svg {
                    vertical-align: middle;
                    width: 18px;
                    margin-top: -10px;
                    // stroke: $theme-body-sub-title-color;
                  }
                }
              }
            }
          }

          &.message-dropdown {
            li {
              &:last-child {
                padding-bottom: 0;
                text-align: center;
              }
            }
          }

          li {
            padding: 15px;

            +li {
              // border-top: 1px solid rgba($dropdown-border-color, 0.3) !important;
            }

            p {
              font-size: 14px;
              letter-spacing: 0.3px;
              margin-bottom: 0;
            }

            a {
              letter-spacing: 0.3px;
            }

            &:last-child {
              padding-bottom: 0;
            }

            .d-flex {
              position: relative;

              img {
                width: 40px !important;
                position: relative;
              }

              .status-circle {
                left: 0;
              }

              .flex-grow-1 {
                >span {
                  letter-spacing: 0.8px;
                  display: inline-block;
                }

                p {
                  margin-bottom: 8px;
                }
              }
            }
          }

          &:before,
          &:after {
            right: 10px !important;
            left: unset !important;
          }
        }

        .notification-box {
          position: relative;
        }

        .cart-box {
          position: relative;
        }

        .cart-dropdown {
          width: 270px;

          ul {
            padding: 0;
          }

          li {
            margin: 0 15px;
            padding: 10px 0;

            .d-flex {
              align-items: center;

              .flex-grow-1 {
                margin-right: 20px;

                span {
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 5px;
                  // color: $theme-font-color;
                }

                h6 {
                  // color: $theme-body-sub-title-color;
                  margin-bottom: 5px;
                }
              }
            }

            &+li {
              margin-top: 0;
              // border-top: 1px solid rgba($dropdown-border-color, 0.1);
            }

            &:last-child {
              padding: 10px 0;
              border-top: none;
            }

            .view-cart,
            .view-checkout {
              font-size: 16px;
              font-weight: 500;
              // color: $theme-font-color;
              transition: all 0.4s;

              &:hover {
                // color: var(--theme-default);
                transition: all 0.4s;
              }
            }
          }

          p {
            line-height: 1.3;
            margin-top: 3px;
            opacity: 0.6;
          }

          .close-circle {
            position: absolute;
            right: 0;
            top: 0;

            a {
              i {
                // color: $theme-body-sub-title-color;
              }
            }

            svg {
              width: 14px;
              stroke: var(--white);
            }
          }

          .total {

            // border-bottom: 1px solid rgba($dropdown-border-color, 0.1);
            h5 {
              font-weight: 500;

              // color: $theme-body-sub-title-color;
              span {
                font-size: 14px;
              }
            }
          }

          .view-checkout {
            text-decoration: unset;
          }
        }

        .notification-box {
          svg {
            animation: swing 1.5s ease infinite;
          }
        }

        .notification-dropdown {
          .d-flex {
            padding: 15px 15px 15px 15px;

            // border-bottom: 1px solid $light-gray;
            &:last-child {
              border-bottom: none;
            }

            &.figma-line {
              border-bottom: none;
            }

            img {
              border-radius: 50px;
            }

            .flex-grow-1 {
              a {
                display: block;

                h5 {
                  font-size: 14px;
                  font-weight: 500;
                  // color: $theme-font-color;
                  margin-bottom: 5px;

                  strong {
                    // color: $theme-body-sub-title-color;
                    font-weight: 500;
                  }
                }

                span {
                  font-size: 13px;
                  font-weight: 400;
                  // color: $theme-body-sub-title-color;
                }
              }
            }

            .flex-shrink-0 {
              div[class*="activity-dot-"] {
                margin-top: 3px;
                animation: round 1.3s ease-in-out infinite;
              }
            }
          }
        }

        .profile-dropdown {
          width: 160px;
          top: 57px;
          left: -12px;
          padding: 0 10px;

          li {
            padding: 10px !important;

            a {
              text-decoration: unset;
            }

            &:hover {
              a {
                span {
                  // color: var(--theme-default);
                  transition: color 0.3s ease;
                }

                svg {
                  // stroke: var(--theme-default);
                  transition: stroke 0.3s ease;

                  path {
                    // stroke: var(--theme-default);
                    transition: stroke 0.3s ease;
                  }
                }
              }
            }

            +li {
              margin-top: 0;
            }

            &:last-child {
              padding-top: 10px !important;
            }

            span {
              color: var(--dark-color);
            }

            svg {
              width: 16px;
              vertical-align: bottom;
              margin-right: 10px;
              stroke: var(--dark-color);
              fill: none !important;
            }
          }
        }
      }
    }
    .toggle-sidebar {
      display: none;
    }
    .main-navbar{
      .header-search{
        width: 30%;
        @media screen and (max-width: 991px) {
          position: absolute;
          left: 68px; 
        }
        @media screen and (max-width: 767px) {
          display: none; 
        }
        input {
          padding: 8px;
          border: 1px solid var(--common-border);
          border-radius: 5px;
          background-color: var(--white) !important;
          color: var(--main-title-color);
          opacity: 1;
          width: 100%;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .common-flex{
        .light-box {
          background-color: var(--font-color);
          i {
            color: $white;
            &.fa-compress {
              animation: none !important; 
            }
          }
        }
      }
    }
  }

  .page-body-wrapper {
    height: 100%;

    .page-body {
      position: relative;
      transition: 0.5s;

      &:before {
        animation: fadeIn 0.5s ease-in-out;
      }

      .card {
        box-shadow: var(--card-boxshadow);
        background-color: var(--white);
        border: unset;
        border-radius: 8px;
        margin-bottom: 5px;
        .card-header {
          background-color: var(--white);
          padding: 18px;
          border-radius: 8px 8px 0 0;
          border-bottom: 1px solid var(--common-border);
          &.avail-info {
            border-bottom: none !important;
          } 
          h4 {
            margin-bottom: 0;
            font-weight: 600;
            color: var(--table-font-color);
          }
        }
      }
    }

    .page-title {
      // padding: $page-title-padding;
      margin: 0 -27px 0;

      >.row {
        margin: 0;

        .col-6 {
          padding: 0;
        }
      }

      .row {
        align-items: center;

        h3 {
          // text-transform: $page-title-text-tranform;
          // color: $theme-font-color;
          font-weight: 500;
          font-size: 22px;

          small {
            // display: $page-small-title-display;
            // font-size: $page-small-title-font-size;
            // margin-bottom: $page-small-title-margin-bottom;
            // text-transform: $page-small-title-text-transform;
            // color: $theme-body-sub-title-color;
          }
        }
      }

      .breadcrumb {
        padding: 0;
        margin-bottom: 0;
        align-items: center;
        justify-content: flex-end;

        li {
          &:first-child {
            position: relative;
            display: flex;
            height: 30px;
            width: 30px;
            align-items: center;
            justify-content: center;
            background-color: rgba($primary-color, 0.1);
          }
        }

        .breadcrumb-item {
          // color: var(--theme-default);
          font-weight: 500;

          &+.breadcrumb-item {
            &:before {
              // content: $breadcrumb-content;
              // color: var(--theme-default);
            }
          }

          a {
            svg {
              width: 16px;
              height: 16px;
              stroke: $primary-color;
              vertical-align: middle;
            }
          }

          &.active {
            color: rgba($primary-color, 0.6);
          }
        }
      }
    }

    div.sidebar-wrapper {
      .sidebar-main {
        .sidebar-links {
          .simplebar-wrapper {
            .simplebar-mask {
              .simplebar-content-wrapper {
                .simplebar-content {
                  >li {
                    a {
                      svg {
                        &.stroke-icon {
                          width: 19px;
                          height: 19px;
                          // stroke: $sidebar-fill-icon-color;
                        }

                        &.fill-icon {
                          width: 19px;
                          height: 19px;
                          // fill: $sidebar-fill-icon-color;
                        }
                      }
                    }
                  }

                  li {
                    a {
                      display: block;
                      color: #363636;
                      line-height: 23px;
                      text-decoration: none;
                      text-transform: capitalize;
                      padding: 5px 15px;
                      margin: 0 3px;
                      letter-spacing: 0.04em;
                      position: relative;
                      border-radius: 5px;
                      font-weight: 500;
                      -webkit-transition: all 0.3s ease;
                      transition: all 0.3s ease;
                    }
                  }
                }
              }
            }
          }
          .icon-sidebar {
            display: none !important;
          }
          .sidebar-listed-custom {
            height: calc(100vh - 115px);
            overflow-y: auto;
            overflow-x: hidden;
          }
        }
      }
      &.close_icon {
        .sidebar-main {
          .sidebar-links {
            .icon-sidebar {
              display: block !important;
            }
            .back-btn{
              display: none !important;
            }
          }
        }
        &:hover {
          .sidebar-main {
            .sidebar-links {
              .icon-sidebar {
                display: none !important;
              }
              .back-btn{
                display: block !important;
              }
            }
          }
        }
      }
    }
    .page-main-body{
      margin-top: 80px;
      margin-left: 300px;
      transition: all .4s ease-in-out;
      @media screen and (max-width: 991px) { 
        margin-left: 0!important; 
        // width: calc(100% - 0px) !important; 
      }
    }
  }
}

@keyframes pagebody {
  from {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }

  50% {
    opacity: 1;
  }
}

@media screen and (max-width: 1480px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -18%;
                          width: 91%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1460px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -54%;
                          width: 75%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-wrapper {
    .sidebar-main {
      .sidebar-links {
        .simplebar-wrapper {
          .simplebar-mask {
            .simplebar-content-wrapper {
              .simplebar-content {
                >li {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -49%;
                          width: 70%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;

      &:before,
      &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .mobile-title {
    &.d-none {
      display: flex !important;
      justify-content: space-between;
      padding: 20px 30px 17px;
      border-bottom: 1px solid #ddd;
      margin: 0 -15px;

      h5 {
        // color: var(--theme-default);
      }

      svg {
        // stroke: var(--theme-default) !important;
      }
    }
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        &.sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      >li {
                        .mega-menu-container {
                          right: -35%;
                          width: 56%;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .main-navbar {
    .toggle-sidebar {
      display: block !important;
      height: 40px;
      width: 40px;
      background-color: rgba($primary-color, 0.1);
      border-radius: 10px;
      .sidebar-toggle {
        i {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center; 
          padding: 12px;
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .page-header {
    .header-wrapper {
      .nav-right {
        .notification-dropdown {
          top: 46px;
        }
      }
     
    }
  }

  .responsive-btn {
    display: none;
  }

  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-main {
          .sidebar-links {
            .simplebar-mask {
              top: 0;
            }

            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    >li {
                      .sidebar-submenu {
                        width: 200px;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                        transition: all 0.5s ease;

                        a {
                          .sub-arrow {
                            position: absolute;
                            right: 20px;
                            top: 8px;
                            // color: $light-semi-font;
                            font-weight: 100;
                            transition: all 0.5s ease;
                            font-size: 15px;
                          }
                        }

                        li {
                          .opensubmegamenu {
                            display: none;
                            border-radius: 4px;
                          }

                          &:hover {
                            .opensubmegamenu {
                              display: block;
                              position: absolute;
                              left: 200px;
                              background-color: var(--white);
                              top: -10px;
                              padding: 15px 0px 15px 0px;
                              box-shadow: 0 0 1px 0 #898989;
                            }
                          }

                          a {
                            &:hover {
                              .sub-arrow {
                                right: 16px;
                                transition: all 0.5s ease;
                              }
                            }
                          }
                        }
                      }

                      .mega-menu-container {
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.5s ease;
                      }

                      &.hoverd {
                        .sidebar-submenu {
                          opacity: 1;
                          visibility: visible;
                          margin-top: 8px;
                          transition: all 0.5s ease;
                        }
                      }

                      &:hover {
                        .mega-menu-container {
                          opacity: 1;
                          visibility: visible;
                          margin-top: 20px;
                          transition: all 0.5s ease;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .toggle-nav {
          display: none;
        }

        .mobile-back {
          display: none !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {

  // material icon css
  .page-wrapper {
    &.material-icon {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        height: 200px;
        // background: $gradient-bg-primary;
      }

      .toggle-sidebar {
        color: rgba($white, 0.8);

        &:hover {
          >svg {
            color: $white !important;
            stroke: $white !important;
          }
        }
      }

      .page-header {
        position: relative;
        background: transparent;
        padding-left: 90px;
        max-width: 1660px;
        margin: 0 auto;
        width: calc(100% - 0px);
        box-shadow: none;

        .f-light {
          color: var(--white);
        }

        .header-wrapper {
          .nav-right {
            ul {
              li {
                svg {
                  stroke: var(--white);
                }

                .profile-dropdown {
                  li {
                    svg {
                      // stroke: $theme-body-font-color;
                    }
                  }
                }
              }
            }

            >ul {
              >li {
                color: var(--white);

                .notification-box,
                .cart-box {
                  svg {
                    stroke: var(--white);
                  }
                }

                >a {
                  >svg {
                    stroke: var(--white);
                  }
                }

                >svg {
                  stroke: var(--white);
                }
              }
            }

            .language-nav {
              .more_lang {
                // color: $theme-body-font-color;
              }
            }

            .onhover-show-div {
              // color: $theme-body-font-color;
            }

            &.right-header {
              ul {
                li {
                  .profile-media {
                    .flex-grow-1 {
                      p {
                        color: rgba($white, 0.7);
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.close_icon {
          padding-left: 0;
          margin: 0 auto !important;
        }
      }

      .page-body-wrapper {
        .page-body {
          margin-top: 0 !important;
          padding-left: 90px;
          max-width: 1660px;
          margin: 0 auto;
        }

        div.sidebar-wrapper {
          &.close_icon {
            ~.page-body {
              padding-left: 15px;
              margin: 0 auto !important;
            }
          }
        }

        .page-title {
          color: var(--white);
          background-color: transparent;
          border-bottom: none;
          box-shadow: none;

          .breadcrumb {
            .breadcrumb-item {
              a {
                svg {
                  stroke: var(--white);
                }
              }

              &.active {
                color: rgba($white, 0.6);
              }

              +.breadcrumb-item {
                &::before {
                  color: rgba($white, 0.8);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {

  // material icon css
  .page-wrapper {
    &.material-icon {
      &::before {
        height: 240px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar-wrapper {
    .menu {
      .brand-logo {
        img {
          width: 120px;
        }
      }
    }
  }

  .page-wrapper {
    .left-header {
      display: none;
    }

    .page-header {
      .header-wrapper {
        .serchinput {
          display: block !important;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .toggle-nav {
    i {
      font-size: 20px;
    }
  }
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  height: 100%;
  line-height: inherit;
  background: #1b1b1b;
  width: 300px;
  text-align: left;
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, .1);
  background-image: url('../../images/sidebar_bg.png');
  .sidebar-main{
    .sidebar-links{
      
      .back-btn{
        padding: 18px 24px;
        position: relative;
        transition: all .4s ease-in-out;
        .mobile-back{
          border-radius: 6px;
          padding: 3px 8px; 
          border: 1px solid rgba($white, 0.3);
          cursor: pointer;
          position: absolute;
          top: 50%;
          right: 17px;
          transform: translateY(-50%);
        }
      }
      .icon-sidebar {
        padding: 18px 16px;
      }
      .sidebar-main-title {
        .sidebar-title { 
          &.active {
            ~ {
              .sidebar-submenu {
                display: block;
              }
            }
          }
          ~  {
            .sidebar-submenu {
              display: none; 
            }
          } 
        }
        .sidebar-link{
          .d-flex {
            h6 {
              transition: all .4s ease-in-out;
            }
          }
        }
        .sidebar-submenu {
          .sidebar-main-title {
            .sidebar-link {
              .d-flex {
                background-color: unset;
                padding-left: 38px;
                &::before {
                  position: absolute;
                  content: "";
                  top: 48%;
                  left: 15px;
                  width: 8px;
                  border-top: 2px solid rgba($white, 0.7);

                }
                &::after {
                  display: none;
                }
              }
              &:hover,&.active {
                .d-flex {
                  &::before {
                    border-color: var(--font-color);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.close_icon {
    width: 77px;
    transition: all 0.3s ease;
    @media screen and (max-width: 991px) { 
      transform : translate(-285px);
    }

    .sidebar-main {
      .sidebar-links {
        .sidebar-heading-title {
          .custom-heading-sidebar {
            display: none ;
          }
        }
        .sidebar-main-title {
          .sidebar-title { 
            &.active {
              ~ {
                .sidebar-submenu {
                  display: none;
                }
              }
            }
            ~  {
              .sidebar-submenu {
                display: none; 
              }
            } 
          }
          .sidebar-link {
            .d-flex {
              justify-content: center;
             
              i {
                margin-right: unset;
              }
              .fa-angle-down {
                display: none;
              }
              .fa-angle-right {
                display: none;
              }
              h6 {
                display: none;
              }
              .according-menu {
                i {
                  display: none;
                }
              }
            }
          }
          // .sidebar-submenu {
          //   display: none !important;
          // }
        }
      }
    }

    &:hover {
      width: 300px;
      .sidebar-main{
        .sidebar-links{
          .sidebar-heading-title {
            .custom-heading-sidebar {
              display: block !important;
            }
          }
          .sidebar-main-title{
            .sidebar-title {
              &.active {
                ~ {
                  .sidebar-submenu {
                    display: block;
                  }
                }
              }
              ~  {
                .sidebar-submenu {
                  display: none; 
                }
              } 
              ~ {
                .sidebar-submenu {
                  .custom-main-title {
                    a {
                      display: block;
                      div {
                        &::before {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-link{
              .d-flex {
                justify-content: unset;
                i {
                  margin-right: 12px;
                }
                .fa-angle-down {
                  display: block !important;
                }
                .fa-angle-right {
                  display: block !important; 
                } 
                h6 {
                  display: block;
                }
                .according-menu {
                  i {
                    display: block;
                  }
                }
              }
            }
            // .sidebar-submenu {
            //   display: block !important;
            // }
          }
        }
      }
    }
    ~ .page-main-body {
      margin-left: 77px;
      @media screen and (max-width: 991px) { 
        margin-left: 0px;
      }
    }
  }
  .sidebar-main-title {
    width: 100%;
    .sidebar-link{
      &.active {
        .d-flex { 
          background-color: rgba($white, 0.1);
          &::after {
            opacity: 1;
          }
          i {
            color: var(--font-color);
          }
          h6 {
            color: var(--font-color);
          }
        }
      }
      .d-flex{
        padding: 14px 12px;
        border-radius: 4px;
        margin:  0 12px;
        position: relative;
        cursor: pointer;
        &::after {
          height: 70%;
          width: 3px;
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: var(--font-color);
          border-radius: 0 10px 10px 0;
          opacity: 0;
        }
        i {
          margin-right: 12px;
          font-size: 18px;
          color: rgba($white, 0.7);
          transition: all .4s ease-in-out;
        }
        h6 {
          width: calc(100% - 18px);
          font-size: 14px;
          font-weight: 600;
          color: rgba($white, 0.7);
          margin-bottom: 0;
          letter-spacing: 0.40px;
          transition: all .4s ease-in-out;
        }
        .according-menu {
          i {
            font-size: 14px;
          }
        }
      }
      &:hover {
        .d-flex {
          i {
            color: var(--font-color);
          }
          h6 {
            color: var(--font-color);
          }
          &::before {
            border-color: var(--font-color);
          }
        }
      }
    }
  }

  .logout-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;

  }
}
.header-search {
  .Typeahead-menu {
    &.is-open {
      position: relative;
      .header-search-suggestion {
        height: 230px;
        width: 100%;
        overflow: auto;
        background-color: $white;
        position: absolute;
        padding: 12px;
      }
    }
    .realname {
      position: relative;
      padding: 10px;
      border: 1px solid var(--light-color1) !important;
      margin: 10px 0px; 
      border-radius: 10px;
      color: $secondary-color; 
      &:hover {
        color: var(--font-color);
      }
    }
  }
}
.header-search {
  .Typeahead-menu {
    .header-search-suggestion {
      .ProfileCard {
        .ProfileCard-details {
          .ProfileCard-realName {
            .realname {
              align-items: center;
            }
          }
        }
      }
    }
  }
}
/**=====================
  5.7 Sidebar CSS Ends
==========================**/