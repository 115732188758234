// Account CSS
table {
  &.account-table {
    thead,
    tbody {
      tr {
        th,
        td {
          .outer-box {
            // width: 34px;
            // height: 34px;
            // background-color: var(--body-color);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            box-shadow: 0px 0px 26px 6px rgba(var(--primary-color), 0.1);
            .btn {
              padding: 0;
            }
          }
          &:last-child {
            .outer-box {
              .trash-icon {
                svg {
                  stroke: $danger-color;
                }
              }
            }
          }
          &:nth-child(6),
          &:nth-child(7) {
            text-align: center;
            i {
              font-size: 20px;
              &.treasury {
                &-cancel {
                  color: $danger-color;
                }
                &-right {
                  color: var(--font-color);
                }
              }
            }
          }
        }
      }
    }
  }
}

// Delete Account Modal
.modal {
  .account-deletion {
    .modal-content {
      padding: 0;
      border-radius: 8px;
      .modal {
        &-body {
          padding: 20px;
          color: var(--dark-color);
          .trash-box {
            text-align: center;
            margin-bottom: 16px;
            i {
              width: 70px;
              height: 70px;
              padding: 18px;
              border-radius: 100%;
              background-color: var(--pagination-bg);
              color: var(--primary-color);
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 28px;
              margin-inline: auto;
            }
            h3 {
              color: $modal-title-color;
            }
            p {
              color: rgba($modal-title-color, 0.8);
            }
          }
        }
        &-footer {
          padding: 20px;
          .common-btn-box {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            margin: 0;
            .btn {
              width: 130px;
            }
          }
          .btn {
            i {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

.sidebar-heading-title {
  > div {
    padding: 8px 0px;
    font-weight: bolder;
    color: var(--font-color);
    margin: 0 12px;
    width: 100%;
    border-radius: 8px;
    p {
      margin: 0;
      text-transform: uppercase;
    }
  }
}
